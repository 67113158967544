import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
const logoUrl = <img src='/assets/images/logo/logo.png' alt='Digital Agency' />;

const SocialShare = [
	{ Social: <FaFacebookF />, link: 'https://www.facebook.com/MezaTecnologia' },
	{
		Social: <FaLinkedinIn />,
		link: 'https://www.linkedin.com/company/mezadigital',
	},
	{ Social: <FaInstagram />, link: 'https://www.instagram.com/meza.digital/' },
	// { Social: <FaTwitter />, link: 'https://twitter.com/' },
];

let currentYear = new Date();
currentYear = currentYear.getFullYear();

class Footer extends Component {
	render() {
		return (
			<React.Fragment>
				<footer
					className='footer-area footer-style-01 bg_color--6'
					id='contact'
				>
					{/* Start Call to Action Area  */}
					<div className='im-call-to-action-area ptb--70 im-separator'>
						<div className='container'>
							<div className='row align-items-center'>
								<div className='col-lg-5 col-xl-5 col-md-12 col-sm-12 col-12'>
									<div className='inner'>
										<h2 className='text-white mb--0 font-size'>
											Enough Talk, Let's Build Something Together
										</h2>
									</div>
								</div>
								<div
									className='col-lg-2 col-xl-2 offset-xl-2 col-md-12 col-sm-12 col-12'
									style={{ marginLeft: 0 }}
								>
									<div className='call-to-cation-tbn text-left text-lg-right mt_md--20 mt_sm--20'>
										<a
											className='btn-default btn-large btn-border btn-opacity'
											href='mailto:hello@grupomeza.com.br'
											target='_blank'
											rel='noopener noreferrer'
											style={{ padding: '30px 30px' }}
										>
											Email us
										</a>
									</div>
								</div>
								<div
									className='col-lg-3 col-xl-3 offset-xl-2 col-md-12 col-sm-12 col-12'
									style={{ marginLeft: 0 }}
								>
									<div className='call-to-cation-tbn text-left text-lg-right mt_md--20 mt_sm--20'>
										<a
											className='btn-default btn-large btn-border btn-opacity'
											href='https://api.whatsapp.com/send?phone=447946823849&text=Oi!%20Gostaria%20de%20falar%20sobre%20um%20novo%20projeto!'
											target='_blank'
											rel='noopener noreferrer'
											style={{ padding: '30px 30px' }}
										>
											Chat now
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* End Call to Action Area  */}

					{/* Start Footer Area  */}
					<div className='footer-wrapper ptb--70'>
						<div className='container'>
							<div className='row'>
								<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
									<div className='ft-text'>
										<div className='logo'>
											<Link to='/'>{logoUrl}</Link>
										</div>
										<p>
											Copyright © {currentYear}{' '}
											<Link to='/'>
												<strong>Grupo Meza Tecnologias</strong>
											</Link>
											. <br /> CNPJ <strong>41.131.894/0001-36</strong> -
											Pelotas, RS.{' '}
										</p>
									</div>
								</div>

								{/* Start Single Widget  */}
								{/* <div className='col-lg-2 col-xl-2 offset-xl-1 col-md-6 col-sm-6 col-12 mt_mobile--40'>
									<div className='footer-link'>
										<h4>Quick Link</h4>
										<ul className='ft-link'>
											<li>
												<Link to='/'>Portfolio</Link>
											</li>
											<li>
												<Link to='/'>About</Link>
											</li>
											<li>
												<Link to='/'>Our Blog</Link>
											</li>
											<li>
												<Link to='/'>Our Team</Link>
											</li>
											<li>
												<Link to='/'>Contact</Link>
											</li>
										</ul>
									</div>
								</div> */}
								{/* End Single Widget  */}

								{/* Start Single Widget  */}
								<div className='row col-lg-4 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40 justify-content-center'>
									{/*	<div className='footer-link'>
										<h4>Company</h4>
										<ul className='ft-link'>
											<li>
												<Link to='/'>Portfolio</Link>
											</li>
											<li>
												<Link to='/'>About</Link>
											</li>
											<li>
												<Link to='/'>Our Team</Link>
											</li>
											<li>
												<Link to='/'>Contact</Link>
											</li>
										</ul>
									</div>*/}
								</div>
								{/* End Single Widget  */}

								{/* Start Single Widget  */}
								<div className='row justify-content-center col-lg-4 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40'>
									<div className='footer-link'>
										<h4>Get in touch</h4>
										<ul className='ft-link'>
											<li>
												Email:{' '}
												<a
													href='mailto:hello@meza.digital'
													target='_blank'
													rel='noopener noreferrer'
												>
													hello@meza.digital
												</a>
											</li>
											<li>
												Phone:{' '}
												<a
													href='https://api.whatsapp.com/send?phone=447946823849&text=Oi!%20Gostaria%20de%20falar%20sobre%20um%20novo%20projeto!'
													target='_blank'
													rel='noopener noreferrer'
												>
													+44 7946 823849
												</a>
											</li>
										</ul>

										<div className='social-share-inner mt--20'>
											<ul className='social-share social-style--2 d-flex justify-content-start liststyle'>
												{SocialShare.map((val, i) => (
													<li key={i}>
														<a
															href={`${val.link}`}
															target='_blank'
															rel='noopener noreferrer'
														>
															{val.Social}
														</a>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
								{/* End Single Widget  */}
							</div>
						</div>
					</div>
					{/* End Footer Area  */}
				</footer>
			</React.Fragment>
		);
	}
}
export default Footer;
