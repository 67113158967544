import PropTypes from "prop-types";
import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.pageTitle}</title>
                    <meta name="description" content="Experience-Driven Digital Agency® - we deliver award-winning UI/UX experiences. From ecommerce to fintech apps, we plan and execute. We deliver digital products fast. Our team is commited to solve challenges and complex issues." />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                </Helmet>
            </React.Fragment>
        )
    }
}
PageHelmet.propTypes = {
    title: PropTypes.string
};
export default PageHelmet;
