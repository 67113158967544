import React from 'react';
import { FaInstagram, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import { FiX, FiMenu } from 'react-icons/fi';

export function Header(props) {
	function menuTrigger() {
		document.querySelector('.header-wrapper').classList.toggle('menu-open');
	}
	function CLoseMenuTrigger() {
		document.querySelector('.header-wrapper').classList.remove('menu-open');
	}

	const scrollToItem = (hash) => {
		const idToScroll = document.querySelector(hash);

		if (!idToScroll) return null;

		idToScroll.scrollIntoView({
			behavior: 'smooth',
		});

		window.history.replaceState(null, null, hash);

		document.querySelector('.header-wrapper').classList.remove('menu-open');
	};

	const { color = 'default-color', logoname } = props;
	let logoUrl = (
		<img src={`/assets/images/logo/logo.png`} alt='Digital Agency' />
	);

	if (logoname) {
		logoUrl = (
			<img
				src={`/assets/images/logo/${logoname}`}
				alt='Digital Agency'
				{...props}
			/>
		);
	}

	const SocialShare = [
		{
			Social: <FaInstagram />,
			link: 'https://www.instagram.com/meza.digital/',
		},
		{
			Social: <FaLinkedinIn />,
			link: 'https://www.linkedin.com/company/mezadigital',
		},

		{
			Social: <FaFacebookF />,
			link: 'https://www.facebook.com/MezaTecnologia',
		},
	];

	const openLink = (hash) => {
		const path = window.location.pathname;

		if (path !== '/') {
			window.open(`/${hash}`, '_self');
		} else {
			scrollToItem(hash);
		}
	};

	return (
		<header
			className={`header-area header--fixed formobile-menu header--transparent ${color}`}
		>
			<div className='header-wrapper' id='header-wrapper'>
				<div className='header-left'>
					<div className='logo'>
						<a href='/'>{logoUrl}</a>
					</div>
				</div>
				<div className='header-right'>
					<nav className='mainmenunav'>
						<ul className='mainmenu'>
							<li onClick={() => openLink('#services')}>Services</li>
							<li onClick={() => openLink('#projects')}>Projects</li>
							<li onClick={() => openLink('#partners')}>Partners</li>
							<li onClick={() => openLink('#contact')}>Contact</li>
							<ul className='inner-ul'>
								{SocialShare.map((val, i) => (
									<li key={i}>
										<a
											href={`${val.link}`}
											target='_blank'
											rel='noopener noreferrer'
										>
											{val.Social}
										</a>
									</li>
								))}
							</ul>
						</ul>
						<ul className='mobile-icons'>
							<span>|</span>
							{SocialShare.map((val, i) => (
								<li key={i}>
									<a
										href={`${val.link}`}
										target='_blank'
										rel='noopener noreferrer'
									>
										{val.Social}
									</a>
								</li>
							))}
						</ul>
					</nav>
					{/* <div className='header-btn'>
							<a
								className='btn-default btn-border btn-opacity'
								target='_blank'
								rel='noopener noreferrer'
								href='https://api.whatsapp.com/send?phone=447946823849&text=Oi!%20Gostaria%20de%20falar%20sobre%20um%20novo%20projeto!'
							>
								<span>Chat Now</span>
							</a>
						</div> */}
					{/* Start Humberger Menu */}
					<div className='humberger-menu d-block d-lg-none pl--20 pl_sm--10'>
						<span onClick={menuTrigger} className='menutrigger text-white'>
							<FiMenu />
						</span>
					</div>
					{/* End Humberger Menu */}
					<div className='close-menu d-block d-lg-none'>
						<span onClick={CLoseMenuTrigger} className='closeTrigger'>
							<FiX />
						</span>
					</div>
				</div>
			</div>
		</header>
	);
}
export default Header;
