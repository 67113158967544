import React, { Component } from 'react';
import { Link } from 'react-router-dom';
const Portfolio_image = <div className='project_background1' />;
const Portfolio_image2 = <div className='project_background2' />;
const Portfolio_image3 = <div className='project_background3' />;

const PortfolioList = [
	{
		image: Portfolio_image,
		category: 'Website',
		title: 'EducaSafras',
		description: 'Business school focused on rural families.',
		link: 'https://educasafras.safrasecifras.com.br/',
	},
	{
		image: Portfolio_image2,
		category: 'Website',
		title: 'Lupa News',
		description: 'A hub of solutions to combat disinformation.',
		link: 'https://lupa.uol.com.br/',
	},
	{
		image: Portfolio_image3,
		category: 'Blog',
		title: 'Your money is worth more',
		description: 'Financial tips and articles from experts.',
		link: 'https://www.oseudinheirovalemais.com.br/',
	},
];

class Portfolio extends Component {
	render() {
		let title = 'Some of our Recent Works',
			description = 'Our hands are in notable projects.';
		return (
			<React.Fragment>
				<div className='portfolio-wrapper'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-12' id='projects'>
								<div className='section-title text-center'>
									<span className='subtitle'>Our projects</span>
									<h2 className='title'>{title}</h2>
									<p
										className='description'
										dangerouslySetInnerHTML={{ __html: description }}
									></p>
								</div>
							</div>
						</div>
						<div className='row mt--30'>
							{PortfolioList.map((value, index) => (
								<div
									className='col-lg-4 col-md-6 col-sm-6 col-12 mt--30'
									key={index}
								>
									<div className='im_portfolio'>
										<div className='thumbnail_inner'>
											<div className='thumbnail'>
												<Link to={value.link}>{value.image}</Link>
											</div>
										</div>
										<div className='content'>
											<div className='inner'>
												<div className='portfolio_heading'>
													<div className='category_list'>
														<Link to={value.link}>{value.category}</Link>
													</div>
													<h4 className='title'>
														<Link to={value.link}>{value.title}</Link>
													</h4>
												</div>
												<div className='portfolio_hover'>
													<p>{value.description}</p>
												</div>
											</div>
										</div>
										<a
											className='transparent_link'
											href={value.link}
											target='_blank'
											rel='noopener noreferrer'
										>
											.
										</a>
									</div>
								</div>
							))}
						</div>
						<div className='row'>
							<div className='col-lg-12'>
								<div className='view-more-btn mt--60 text-center'>
									<a className='btn-default' href='/projects'>
										<span>View More Projects</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default Portfolio;
