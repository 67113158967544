import React, { Component } from "react";
import { FiSend } from "react-icons/fi";

import { Link } from 'react-router-dom';
class About extends Component
{
    render()
    {
        let title = 'Made in Brazil<br/>Present Everywhere.',
            description = 'We are born in Brazil but we deliver digital products accross the continents of the Earth. Our results are award-winning and present everywhere.',
            description2 = 'In 2021 we architectured and delivered a magnific UI/UX design for the <b>Álbum de Campeão SporTV</b> – a card game for the <b>Tokyo Olympics 2020</b>.';

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail pulse">
                                    <img style={{ width: '85%', boxShadow: '0 25px 55px rgb(253 71 102 / 22%)' }} src="/assets/images/about/about-1.png" alt="About Images" />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <FiSend />
                                        </div>
                                        <h2 className="title" dangerouslySetInnerHTML={{ __html: title }}></h2>
                                        <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                                        <p className="description" dangerouslySetInnerHTML={{ __html: description2 }}></p>
                                        <div className="purchase-btn">
                                            <Link className="btn-transparent" to={{ pathname: 'https://play.google.com/store/apps/details?id=com.globo.sportv.albumdecampeao' }} target="_blank">PLAY ON ANDROID</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;