import React from 'react';

function BrandTwo() {
	const brandsList = [
		{ src: '/assets/images/brand/brand-01.png', alt: 'Safras e Cifras' },
		{ src: '/assets/images/brand/brand-06.png', alt: 'Agência Lupa' },
		{ src: '/assets/images/brand/brand-02.png', alt: 'Banco de Brasília' },
		{ src: '/assets/images/brand/brand-03.png', alt: 'FlagCx' },
		{ src: '/assets/images/brand/brand-05.png', alt: 'Sicoob' },
		{ src: '/assets/images/brand/brand-04.png', alt: 'W2 Digital' },
	];

	return (
		<ul className='brand-style-2'>
			{brandsList.map((item, key) => (
				<li key={key}>
					<img src={item.src} alt={item.alt} />
				</li>
			))}
		</ul>
	);
}

export default BrandTwo;
