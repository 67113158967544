import React, { Component } from "react";
import { FiActivity, FiCast, FiMap } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'Awarded Design',
        description: 'We deliver award-winning UI/UX experiences. From ecommerce to fintech apps, we plan and execute.'
    },
    {
        icon: <FiCast />,
        title: 'Agile Development',
        description: 'From mobile apps to web, dashboards, and ecommerce - we deliver digital products fast.'
    },
    {
        icon: <FiMap />,
        title: 'Consultancy',
        description: 'Our team is commited to solve challenges and complex issues. Get in touch and let us help.'
    },
]
class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row row--25">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;