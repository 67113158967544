import React, { Component } from 'react';
import {
	FiCast,
	FiLayers,
	FiUsers,
	FiMonitor,
	/*FiChevronUp*/ FiMail,
	FiCopy,
} from 'react-icons/fi';

const ServiceList = [
	{
		icon: <FiCast />,
		title: 'Business Stratagy',
		description:
			"We improve brand's results through technology and business inteligence.",
	},
	{
		icon: <FiLayers />,
		title: 'Website Development',
		description:
			'We build digital presence - from landing pages to dashboards and hotsites.',
	},
	{
		icon: <FiUsers />,
		title: 'Marketing & Reporting',
		description:
			'Our marketing consultancy helps brands understand numbers and sell more.',
	},
	{
		icon: <FiMonitor />,
		title: 'Mobile Development',
		description:
			'Magnific Android and iOS mobile applications built and published blazing fast.',
	},
	{
		icon: <FiMail />,
		title: 'Email Marketing',
		description:
			'Neat templates for e-mail marketing with a lower risk of cauthing on SPAM filters.',
	},
	{
		icon: <FiCopy />,
		title: 'Copywriting',
		description:
			'Our team can deliver the best sentences for each design and experience you have.',
	},
];

class ServiceTwo extends Component {
	render() {
		let title = 'Our Services.',
			description = 'We specialize in creating unique journeys',
			subtitle = 'What we can do for you';
		return (
			<React.Fragment>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12' id='services'>
							<div className='section-title text-center'>
								<span className='subtitle'>{subtitle}</span>
								<h2 className='title'>{title}</h2>
								<p
									className='description'
									dangerouslySetInnerHTML={{ __html: description }}
								></p>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-lg-12 col-12 mt--30'>
							<div className='row service-main-wrapper'>
								{ServiceList.map((val, i) => (
									<div className='col-lg-4 col-md-6 col-sm-6 col-12' key={i}>
										<div className='service service__style--2 text-left'>
											<div className='icon'>{val.icon}</div>
											<div className='content'>
												<h3 className='title'>{val.title}</h3>
												<p>{val.description}</p>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default ServiceTwo;
