import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component {
	render() {
		return (
			<React.Fragment>
				<div className='row'>
					<div className='col-lg-12'>
						<Tabs>
							<div className='row align-items-center'>
								<div className='col-lg-6'>
									<TabPanel>
										<div className='rn-testimonial-content text-left'>
											<div className='inner'>
												<p>
													<b>John</b> is a born leader with 8+ years of
													experience and have worked in projects for Netflix,
													SporTV and other notable brands in the digital market.
												</p>
											</div>
											<div className='author-info'>
												<h6>
													<span>João Viana (John) </span> -{' '}
													<b>Founder and CEO</b>, MEZA DIGITAL.
												</h6>
											</div>
										</div>
									</TabPanel>

									<TabPanel>
										<div className='rn-testimonial-content text-left'>
											<div className='inner'>
												<p>
													<b>Gustavo</b> leads the projects area, is responsible
													for optimizing operations and processes, has
													experience managing national and international
													projects using agile methods.
												</p>
											</div>
											<div className='author-info'>
												<h6>
													<span>Gustavo Schuster </span> -{' '}
													<b>Chief Operating Officer </b>, MEZA DIGITAL.
												</h6>
											</div>
										</div>
									</TabPanel>
								</div>
								<div className='col-lg-6 mt_md--40 mt_sm--40'>
									<TabList className='testimonial-thumb-wrapper'>
										<Tab>
											<div className='testimonial-thumbnai'>
												<div className='thumb'>
													<img
														src='/assets/images/client/testimonial-1.jpg'
														alt='João Pedro Viana'
													/>
												</div>
											</div>
										</Tab>
										<Tab>
											<div className='testimonial-thumbnai'>
												<div className='thumb'>
													<img
														src='/assets/images/client/testimonial-3.jpg'
														alt='Gustavo Schuster'
													/>
												</div>
											</div>
										</Tab>
									</TabList>
								</div>
							</div>
						</Tabs>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default Testimonial;
