import React, { Component } from 'react';
const Portfolio_image = (
	<img
		src='/assets/images/portfolio/pucpr.png'
		alt='Pontifical Catholic University of Paraná'
	/>
);
const Portfolio_image2 = (
	<img src='/assets/images/portfolio/matchone.png' alt='MatchOne' />
);
const Portfolio_image3 = (
	<img src='/assets/images/portfolio/betai.png' alt='React Creative Agency' />
);
const Portfolio_image4 = (
	<img src='/assets/images/portfolio/educasafras.png' alt='EducaSafras' />
);
const Portfolio_image5 = (
	<img src='/assets/images/portfolio/brb55anos.png' alt='BRB 55 Years' />
);
const Portfolio_image6 = (
	<img src='/assets/images/portfolio/sicoob.png' alt='Sicoob' />
);
const Portfolio_image7 = (
	<img src='/assets/images/portfolio/lupa-news.png' alt='Lupa News' />
);
const Portfolio_image8 = (
	<img src='/assets/images/about/about-1.png' alt='Álbum de Campeão SportTV' />
);
const Portfolio_image9 = (
	<img src='/assets/images/portfolio/netflix-2.png' alt='Tudum Netflix' />
);

const PortfolioListContent = [
	{
		image: Portfolio_image7,
		category: 'Website',
		title: 'Lupa News',
		description: 'A hub of solutions to combat disinformation.',
		link: 'https://lupa.uol.com.br',
	},
	{
		image: Portfolio_image9,
		category: 'Landing Page',
		title: 'Netflix Tudum',
		description: 'Celebration of 10 years of TUDUM.',
		link: 'https://www.tudumnetflix.com.br/',
	},
	{
		image: Portfolio_image8,
		category: 'Platform',
		title: 'Álbum de Campeão SportTV',
		description: 'A card game for the Tokyo Olympics 2020.',
		link: 'https://play.google.com/store/apps/details?id=com.globo.sportv.albumdecampeao',
	},
	{
		image: Portfolio_image3,
		category: 'Landing Page',
		title: 'Beta-I',
		description: 'A global collaborative innovation consultancy.',
		link: 'https://beta-i.com',
	},
	{
		image: Portfolio_image,
		category: 'Landing Page',
		title: 'PUCPR',
		description: 'Reference university in Brazil.',
		link: 'https://multiversidade.pucpr.br',
	},
	{
		image: Portfolio_image6,
		category: 'Blog',
		title: 'Your money is worth more',
		description: 'Financial tips and articles from experts.',
		link: 'https://www.oseudinheirovalemais.com.br/',
	},
	{
		image: Portfolio_image4,
		category: 'Website',
		title: 'EducaSafras',
		description: 'Business school focused on rural families.',
		link: 'https://educasafras.safrasecifras.com.br/',
	},
	{
		image: Portfolio_image5,
		category: 'Landing Page',
		title: 'BRB 55 Years',
		description: '55 years of Banco de Brasília.',
		link: 'https://brb55anos.brb.com.br/',
	},
	{
		image: Portfolio_image2,
		category: 'Platform',
		title: 'MatchOne',
		description: 'Connecting companies and talents.',
		link: 'https://www.matchone.com.br/',
	},
];

class PortfolioList extends Component {
	render() {
		const { column, styevariation } = this.props;
		const list = PortfolioListContent.slice(0, this.props.item);
		return (
			<React.Fragment>
				{list.map((value, index) => (
					<div className={`${column}`} key={index}>
						<div className={`im_portfolio ${styevariation}`}>
							<div className='thumbnail_inner'>
								<div className='thumbnail'>
									<a
										href={value.link}
										target='_blank'
										rel='noopener noreferrer'
									>
										{value.image}
									</a>
								</div>
							</div>
							<div className='content'>
								<div className='inner'>
									<div className='portfolio_heading'>
										<div className='category_list'>
											<a
												href={value.link}
												target='_blank'
												rel='noopener noreferrer'
											>
												{value.category}
											</a>
										</div>
										<h4 className='title'>
											<a
												href={value.link}
												target='_blank'
												rel='noopener noreferrer'
											>
												{value.title}
											</a>
										</h4>
									</div>
									<div className='portfolio_hover'>
										<p>{value.description}</p>
									</div>
								</div>
							</div>
							<a
								className='transparent_link'
								href={value.link}
								target='_blank'
								rel='noopener noreferrer'
							>
								{' '}
							</a>
						</div>
					</div>
				))}
			</React.Fragment>
		);
	}
}
export default PortfolioList;
