import React from 'react';
import PageHelmet from '../component/common/Helmet';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Header from '../component/header/Header';
import Footer from '../component/footer/Footer';
import PortfolioList from '../elements/portfolio/PortfolioList';
import Particles from 'react-particles-js';

const Portfolio = () => {
	return (
		<>
			<PageHelmet pageTitle='Projects' />

			{/* Start Header Area  */}
			<Header
				headertransparent='header--transparent'
				colorblack='color--black'
				logoname='logo-purple.png'
				style={{ maxHeight: '70px' }}
			/>
			{/* End Header Area  */}

			{/* Start Slider Area   */}
			<div
				className='slider-activation slider-creative-agency with-particles'
				id='home'
			>
				<div className='frame-layout__particles'>
					<Particles
						params={{
							particles: {
								number: {
									value: 100,
								},
								size: {
									value: 3,
								},
							},
							interactivity: {
								events: {
									onhover: {
										enable: true,
										mode: 'repulse',
									},
								},
							},
						}}
					/>
				</div>
				<div className='im_modern_slider bg_image bg_image--34'>
					<div className='slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center'>
						<div className='container'>
							<div className='row'>
								<div className='col-lg-12'>
									<div className={`inner `}>
										<h1 className='title'>Our Projects</h1>
										<p className='description_style-2'>
											Our collaboration on remarkable projects, providing
											excellence with a competent team.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Slider Area   */}

			{/* Start Page Wrapper  */}
			<main className='page-wrapper'>
				{/* Start Portfolio Area */}
				<div className='portfolio-area ptb--120 bg_color--5'>
					<div className='portfolio-sacousel-inner'>
						<div className='container'>
							<div className='row'>
								<PortfolioList
									styevariation='text-left mt--40'
									column='col-lg-4 col-md-6 col-sm-6 col-12'
									item='8'
								/>
							</div>
						</div>
					</div>
				</div>
				{/* End Portfolio Area */}
			</main>
			{/* End Page Wrapper  */}

			{/* Start Back To Top */}
			<div className='backto-top'>
				<ScrollToTop showUnder={160}>
					<FiChevronUp />
				</ScrollToTop>
			</div>
			{/* End Back To Top */}

			{/* Start Footer Area  */}
			<Footer />
			{/* End Footer Area  */}
		</>
	);
};

export default Portfolio;
